(function () {
    'use strict';

    /* Fixed header on scroll */
    var $header = $("header");
    if ($(window).width() <= 767) {
        $header.addClass("scrolled");
    }
    $(window).scroll(function () {
        if ($(window).width() > 767) {
            if ($(window).scrollTop() > 0) {
                $header.addClass("scrolled");
            } else {
                $header.removeClass("scrolled");
            }
        } else {
            $header.addClass("scrolled");
        }

    });

    /* ScrollTo */
    $(".scrollto").click(function(e){
        e.preventDefault();
        $(window).scrollTo($($(this).attr("data-href")), 500, {offset: 0});

    });

    /* Magnific Popup */
    $(document).ready(function() {
        $(".thumbnailgallery").each(function(i,v){
            $(this).magnificPopup({
                delegate: 'a.imagelink',
                type: 'image',
                tLoading: 'Laden...',
                mainClass: 'mfp-img-mobile',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    preload: [0,1] // Will preload 0 - before current, and 1 after the current image
                },
                image: {
                    tError: '<a href="%url%">Bild kann nicht geladen werden</a>',
                }
            });
        })

    });


    /* BxSlider */
    $(".bxslider").each(function(i,v){
        $(this).css("max-height", $(this).width()*$(this).attr("data-image-height")/$(this).attr("data-image-width"));
    });
    $(window).load(function () {

        if ($(".bxslider > *").length > 1) {
            $(".bxslider").each(function(i,v){
                $(this).css("max-height", "99999em").css("background", "none");
                $(this).bxSlider({auto: true, pause: 7000});
            })
        }
    });

}());
